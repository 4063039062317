define(['app', 'componentHelper', 'urlReader'], function(app, helper, urlReader) {

  /**
   * Page Controls.
   * @namespace
   */
  var _pageControls = {

    /**
     * Module Constructor
     * @private
     */
    _init: function() {
      var sortBy = document.querySelector('.js-sort-by');
      if (sortBy) {
        _pageControls._sortByValue = sortBy.value;
      }
      _pageControls._setCache();

      _pageControls._bindEvents();

      app.subscribe('pageControls/update', function(stringTemplateResponse) {
        for (var i = 0, pageControlsWrapperLength = _pageControls._pageControlsWrapper.length; i < pageControlsWrapperLength; i++) {
          _pageControls._pageControlsWrapper[i].innerHTML = stringTemplateResponse;
        }
        _pageControls._updateSortByEvents();
        helper.reloadAllComponents();
      });

      app.subscribe('pageControls/updateCache', function(selectedPage) {
        _pageControls._cachedPageNumber = selectedPage;
      });

      app.subscribe('pageControls/removeCurrentPage', function() {
        var pageNumberElement = _pageControls._pageControlsWrapper[0].querySelector('.js-current-page');
        if (pageNumberElement) {
          var selectedPageNumber = app.select(pageNumberElement).getAttribute('data-page-number');
          var deconstructedUrl = urlReader.deconstructGet('remove', selectedPageNumber, _pageControls._pageNumberKey);
          _pageControls._cachedPageNumber = '1';
          app.publish('facets/removeCurrentPage', deconstructedUrl.window.url);
        }
      });
    },

    /**
     *
     * Set the current page number cache
     * @private
     */
    _setCache: function() {
      var pageNumber = _pageControls._pageControlsWrapper[0].querySelector('.js-current-page');
      if (pageNumber) {
        _pageControls._cachedPageNumber = app.select(pageNumber).getAttribute('data-page-number');
      }

      var sortBy = _pageControls._getSortByElement()[0];
      if (sortBy && sortBy.value !== '') {
        _pageControls._cachedSortByValue = sortBy.value;
      }
    },

    _pageControlsWrapper: document.querySelectorAll('.js-page-controls'),

    _sortByKey: 'sortOrder',

    _pageNumberKey: 'pageNumber',

    _cachedSortByElements: '',

    _cachedSortByValue: '',

    _cachedPageNumber: '1',

    /**
     * Bind an event to the module container
     * @private
     */
    _bindEvents: function() {
      app.element.on('click', _pageControls._pageControlsEventHandler, _pageControls._pageControlsWrapper);
      _pageControls._updateSortByEvents();
    },

    /**
     * Remove and add events to stop memory leaks
     * @private
     */
    _updateSortByEvents: function() {
      var newSortByElements = _pageControls._getSortByElement();
      if (_pageControls._cachedSortByElements) {
        app.element.off('change', _pageControls._pageControlsEventHandler, _pageControls._cachedSortByElements);
      }
      app.element.on('change', _pageControls._pageControlsEventHandler, newSortByElements);
      _pageControls._cachedSortByElements = newSortByElements;
    },

    /**
     * Re-traverse the DOM for new sort by elements
     * @returns {NodeList}
     * @private
     */
    _getSortByElement: function() {
      return document.querySelectorAll('.js-sort-by');
    },

    /**
     * Utilise event delegation to determine click behaviour
     * @param event
     * @private
     */
    _pageControlsEventHandler: function(event) {
      event = event || window.event;
      var target = event.target || event.srcElement;

      if (!target) {
        return;
      }

      var pageNumber = '1';

      if (target.nodeName === 'SELECT' && app.element.hasClass('js-sort-by', target)) {
        if (target.value === _pageControls._cachedSortByValue) {
          return;
        }
        _pageControls._cachedSortByValue = target.value;
        _pageControls._cachedPageNumber = pageNumber;
        app.publish('facets/updatePage', 'replace', target.value, _pageControls._sortByKey, true);
      }

      if (target.nodeName === 'A' && app.element.hasClass('js-paging-option', target)) {
        pageNumber = app.element.getAttribute('data-page-number', target);
        _pageControls._cachedPageNumber = pageNumber;
        app.publish('facets/updatePage', 'replace', pageNumber, _pageControls._pageNumberKey);
        event.preventDefault();
      }

      if (target.nodeName === 'A' && app.element.hasClass('js-pagination-navigation', target)) {

        if (app.element.getAttribute('disabled', target) === 'disabled') {
          return false;
        } else {
          var navigationDirection = app.element.getAttribute('data-direction', target);

          if (navigationDirection === 'next') {
            pageNumber = parseInt(_pageControls._cachedPageNumber, 10) + 1;
          } else {
            pageNumber = parseInt(_pageControls._cachedPageNumber, 10) - 1;
          }

          if (pageNumber !== _pageControls._cachedPageNumber) {
            app.publish('facets/updatePage', 'replace', pageNumber, _pageControls._pageNumberKey);
            _pageControls._cachedPageNumber = pageNumber;
          }
        }
        event.preventDefault();
      }
    }
  };

  // Execute module constructor
  if (document.querySelector('.js-page-controls')) _pageControls._init();

  // Unit testing access
  return _pageControls;
});
